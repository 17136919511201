.header {
    margin-bottom: 40px;
}

.header-bar {
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
}

.logo-size img {
    width: 228px;
    height: 77px;
}

.site-brand__text {
    font-family: 'Roboto-Light', 'Roboto Light', 'Roboto', sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 1.325em;
}

@media (min-width: 568px) {
    .header-bar {
        display: flex;
    }
}