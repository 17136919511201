.red {
    color: #d32205;
}
.twoDigit {
    width: 60px;
    min-width: 60px !important;
    margin-bottom: 10px;
}

.fourDigit {
    width: 120px;
    min-width: 120px !important;
}

.Row {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.Column {
    display: table-cell;
}

.verticalLine {
    border-left: 10px solid #b1b4b6;
    padding: 15px 72px 24px 32px;
    margin-bottom: 40px;
}
.verticalLine.details{
    border-left: 8px solid #ebebeb;
    padding: 15px 72px 10px 15px;
    margin-top: 10px;
}
.verticalLine p:last-child {
    margin-bottom: 0;
}

.wrapper {
    margin: 0 auto;
    padding: 0 15px;
}

.clearfix:before, 
.clearfix:after {
    content: " ";
    display: table;
}

.grid.date {
    margin-top: 16px;
}
.grid.date>div {
    margin-right: 16px;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

.skip-link:focus {
    outline: 3px solid #ffdd0;
    background-color: #ffdd0;
    color: #0b0c0c;
    /*position: static !important;
    width: auto !important;
    height: auto !important;
    margin: inherit !important;
    overflow: visible !important;
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    white-space: inherit !important;*/
}

.govuk-summary-list {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.govuk-summary-list__row {
    margin-bottom: 15px;
    border-bottom: 1px solid #b1b4b6;
}
.govuk-summary-list__rowHidden {
    display: none;
}
.govuk-summary-list__key,
.govuk-summary-list__value,
.govuk-summary-list__actions {
    margin: 0;
}
.govuk-summary-list__key {
    margin-bottom: 5px;
}
.govuk-summary-list__actions,
.govuk-summary-list__value {
    margin-bottom: 15px;
}
.govuk-summary-list__key, 
.govuk-summary-list__value{
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.govuk-summary-list__value p {
    margin-bottom: 0;
}
.govuk-summary-list__key .font-large {
    margin-top: 0;
    font-size: 1em;
    line-height: 1.31579;
}
.mobile-grid {
    display: flex;
    align-items: center;
}
@media (min-width: 641px) {
    .govuk-summary-list__row {
        display: table-row;
        margin-bottom: 0;
        border-bottom: 0;
    }
    .govuk-summary-list__key {
        width: 30%;
    }
    .govuk-summary-list__value {
        width: 50%;
    }
    .govuk-summary-list__key,
    .govuk-summary-list__value,
    .govuk-summary-list__actions {
        display: table-cell;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #b1b4b6;
    }
    .govuk-summary-list__actions {
        width: 20%;
        padding-right: 0;
        text-align: right;
    }
    .govuk-summary-list__key .font-large {
        margin-top: 0;
        font-size: 1.1875rem;
        line-height: 1.31579;
    }
    
}
@media (min-width: 768px) {
    .wrapper {
        max-width: 750px;
    }    
    .grid {
        display: flex;
        align-items: center;
    }
    .grid__item {
        width: 50%;
    }
    .twoDigit {
        margin-bottom: 0;
    }
}

@media (min-width: 992px){
    .wrapper {
        max-width: 970px;
    }
}

@media (min-width: 1200px) {
    .wrapper {
	    max-width: 1170px;
    }
    form {
        padding-left: 30px;
    }
    .step:not(.step-22) {
        width: 67%;
    }
    .govuk-error-summary {
        width: 67%;
    }
}