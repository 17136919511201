html {
    height: 100%;
}
body {
    font-family: 'Roboto',Arial;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-size: 16px;
    /*line-height: 21px;*/
    background: #fff;
    color: #333;
    font-weight: 400;
    margin: 0;
    overflow-x: hidden;
    letter-spacing: normal;	
    min-height: calc(100% - 470px);
    position: relative;
}

p {
    margin: 0 0 21px 0;
}
svg:not(:root) {
    overflow: hidden;
}
h1 {
    font-size: 1.75em;
    line-height: 1.215em;
    margin: 0 0 28px -1px;
    font-family: 'Roboto-Bold', 'Roboto Bold', 'Roboto', sans-serif;
}
@media only screen and (min-width: 768px) {
    h1 {
        font-size: 2.58em;
        line-height: 1.15em;
        margin: 0 0 35px -2px;
        font-weight: 700;
    }
    
    body {
        font-size: 1.1875em;
	line-height: 1.474em;
    }
    p {
        margin: 0 0 28px 0;
    }
}
@media (min-width: 1024px) {
    body {
        padding-bottom: 362px;
        min-height:calc(100% - 362px);
    }
    .govuk-error-summary { 
        padding: 20px;
        margin-bottom: 50px;
    }
}