.site-footer {
    background: #0065bd;
    color: #fff;
    padding: 0 15px 30px 15px;
    margin-top: 144px;
}
.site-items {
    border-bottom: 1px solid rgba(255,255,255,0.2);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 10px -15px 21px;
    padding: 21px 0;
    list-style: none;
}
.site-items li {
    padding: 0 0 20px 0;
}
.site-items a {
    color: #fff;
    font-size: 0.685em;
    font-weight: 700;
}

.site-footer__gov-logo img {
    width: 165px;
    margin-top: 40px;
}
.site-footer__summary {
    font-size: 0.85em;
}

@media (min-width: 768px) {
    .site-footer__gov-logo img {
        float: right;
        margin-top: 0;
    }
}
@media (min-width: 1024px) {
    .site-items {
        display: flex;
    }
    .site-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .site-items li {
        padding: 0 40px 0 8px;
    }
}